<template>
  <div>
    <v-container>
      <v-row no-gutters>
        <v-col
          cols="12"
          sm="6"
          class="text-uppercase display-2 font-weight-black"
        >
          News Page
          <v-col>
            <v-btn color="primary" class="text-uppercase" @click="addnew">
              Add News
            </v-btn>
          </v-col>
        </v-col>
      </v-row>
      <v-dialog v-model="dialog1" persistent>
        <BaseLoading :loading="loading" />
        <v-card>
          <v-card-title>
            <span class="headline">{{ activeItem }}</span>
          </v-card-title>

          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12" sm="8">
                  <v-col cols="12">
                    <div class="text-capitalize headline mb-5">
                      <span class="red--text">*</span>
                      Body TH
                    </div>
                    <ckeditor
                      v-model="product.body_th"
                      :config="editorConfig"
                    ></ckeditor>
                  </v-col>
                  <v-col cols="12">
                    <div class="text-capitalize headline mb-5">
                      <span class="red--text">*</span>
                      Body Eng
                    </div>
                    <ckeditor
                      v-model="product.body_en"
                      :config="editorConfig"
                    ></ckeditor>
                  </v-col>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-col cols="12">
                    <div class="text-capitalize headline mb-5">
                      <span class="red--text">*</span>
                      Title TH
                    </div>
                    <v-text-field
                      v-model="product.title_th"
                      label="Title TH"
                      outlined
                      :rules="bulletTitleRules"
                      required
                      maxLength="150"
                      counter
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <div class="text-capitalize headline mb-5">
                      <span class="red--text">*</span>
                      Title Eng
                    </div>
                    <v-text-field
                      v-model="product.title_en"
                      label="Title TH"
                      outlined
                      :rules="bulletTitleRules"
                      required
                      maxLength="150"
                      counter
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" v-if="this.modal == 'edit'">
                    <div class="text-capitalize headline mb-5">
                      Add tag "New"
                    </div>
                    <v-divider light></v-divider>
                  </v-col>
                  <v-col cols="12" v-if="this.modal == 'edit'">
                    <v-select
                      :items="items"
                      label="Tag news"
                      outlined
                      required
                      item-value="id"
                      item-text="name"
                      v-model="product.is_new"
                      @change="updateIsNew"
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <div class="text-capitalize headline mb-5">
                      <span class="red--text">*</span>
                      Image Title
                    </div>
                    <v-divider light></v-divider>
                  </v-col>
                  <v-col cols="12">
                    <!--         file for edit         -->
                    <v-file-input
                      v-if="this.modal == 'edit'"
                      v-model="fileSingle"
                      color="deep-purple accent-4"
                      counter
                      accept="image/png, image/jpeg, image/bmp"
                      label="File input"
                      @change="uploadImage"
                      placeholder="Select your files"
                      prepend-icon="mdi-camera"
                      :show-size="1000"
                      :rules="fileSizeRules"
                    >
                      <template
                        v-slot:selection="{
                          index,
                          text,
                        }"
                      >
                        <v-chip
                          v-if="index < 2"
                          color="deep-purple accent-4"
                          dark
                          label
                          small
                        >
                          {{ text }}
                        </v-chip>

                        <span
                          v-else-if="index === 2"
                          class="overline grey--text text--darken-3 mx-2"
                        >
                          +{{ files.length - 2 }}
                          File(s)
                        </span>
                      </template>
                    </v-file-input>
                    <!--         file for edit         -->

                    <!--         file for craete         -->
                    <v-file-input
                      v-if="this.modal == 'new'"
                      :rules="fileRulesNews"
                      required
                      v-model="fileSingle"
                      color="deep-purple accent-4"
                      counter
                      accept="image/png, image/jpeg, image/bmp"
                      label="File input"
                      @change="uploadImage"
                      placeholder="Select your files"
                      prepend-icon="mdi-camera"
                      :show-size="1000"
                    >
                      <template
                        v-slot:selection="{
                          index,
                          text,
                        }"
                      >
                        <v-chip
                          v-if="index < 2"
                          color="deep-purple accent-4"
                          dark
                          label
                          small
                        >
                          {{ text }}
                        </v-chip>

                        <span
                          v-else-if="index === 2"
                          class="overline grey--text text--darken-3 mx-2"
                        >
                          +{{ files.length - 2 }}
                          File(s)
                        </span>
                      </template>
                    </v-file-input>
                    <!--         file for craete         -->
                  </v-col>
                  <v-col cols="12">
                    <v-checkbox v-model="product.is_pin" label="Pin this news">
                    </v-checkbox>
                  </v-col>
                  <v-col cols="12">
                    <!--
                       TODO: move v-select to use this component
                    (we found issue state is bug in BaseEvent Component
                    *not change the same v-model)
                     -->
                    <!-- <BaseEvent
                      :items="events"
                      label="Select your event"
                      itemText="name"
                      itemValue="id"
                      v-model="product.event_id"
                    ></BaseEvent> -->

                    <v-select
                      v-model="product.event_id"
                      label="Select your event"
                      :items="eventsFiltered"
                      item-text="name"
                      item-value="id"
                      dense
                    ></v-select>
                  </v-col>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="saveData()"
              :disabled="!valid"
              v-if="this.modal == 'new'"
            >
              Save
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="save()"
              :disabled="!valid"
              v-if="this.modal == 'edit'"
            >
              Save Edit
            </v-btn>
            <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <BaseLoading :loading="loading" />
      <div class="text-capitalize display-1 my-5">News</div>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        outlined
        label="Search"
        hide-details
      ></v-text-field>
      <v-data-table
        dark
        fixed-header
        height="500"
        :headers="headers"
        :items="news"
        :items-per-page="pageSize"
        :page.sync="page"
        :search="search"
        :sort-desc="['id']"
        hide-default-footer
        class="elevation-1 my-5"
      >
        <template v-slot:item.is_pin="{ item }">
          {{ item.is_pin ? 'Yes' : 'No' }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        </template>
      </v-data-table>
      <v-pagination
        v-model="page"
        :length="totalPages"
        @input="next"
      ></v-pagination>
    </v-container>
  </div>
</template>

<script>
import NewsService from '@/services/NewsService'
import EventServices from '@/services/EventServices'
import { FormMixin } from '@/Mixins/FormMixin'
const util = require('util')

const eventBase = {
  id: 0,
  name: 'Not selected',
  path: '',
  active: true,
}

export default {
  mixins: [FormMixin],
  data() {
    return {
      headers: [
        {
          text: 'Title_TH',
          align: 'start',
          value: 'title_th',
          width: '25%',
        },
        {
          text: 'Title_EN',
          align: 'start',
          value: 'title_en',
          width: '25%',
        },
        {
          text: 'Created_at',
          value: 'created_at',
          align: 'start',
          width: '10%',
        },
        {
          text: 'Updated_at',
          value: 'updated_at',
          align: 'start',
          width: '10%',
        },
        {
          text: 'Tag New',
          value: 'is_new',
          align: 'center',
          width: '10%',
        },
        {
          text: 'Pin News',
          value: 'is_pin',
          align: 'start',
          width: '10%',
        },
        {
          text: 'Event ID',
          value: 'event_id',
          align: 'center',
          width: '10%',
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'end',
          width: '10%',
        },
      ],
      product: {
        body_en: '',
        body_th: '',
        is_new: 1,
        title_en: '',
        title_th: '',
        is_pin: false,
        event_id: null,
      },
      events: [],
    }
  },
  computed: {
    eventsFiltered() {
      return this.events.filter(item => item.active)
    },
  },
  created() {
    this.readData()
  },
  methods: {
    next() {
      this.readData()
    },
    readData() {
      NewsService.getNews({ page: this.page, limit: this.pageSize })
        .then(res => {
          if (res.status === 200) {
            this.news = []
            this.news = res.data.items
            this.totalPages = res.data.meta.totalPages
          }
        })
        .catch(err => {
          this.$swal.fire({
            icon: 'error',
            title: `${err.response.data.message}`,
          })
          if (err.response.status === 401) {
            this.removeToken()
            this.$router.push({ name: 'Admin' })
          }
        })

      EventServices.getEvents()
        .then(res => {
          if (res.status === 200) {
            const newEvents = res.data

            newEvents.unshift(eventBase)
            this.events = newEvents
          }
        })
        .catch(err => {
          this.$swal.fire({
            icon: 'error',
            title: `${err.response.data.message}`,
          })
          if (err.response.status === 401) {
            this.removeToken()
            this.$router.push({ name: 'Admin' })
          }
        })
    },
    updateIsNew(e) {
      this.product.is_new = e
    },
    saveData() {
      if (this.$refs.form.validate()) {
        this.loading = !this.loading
        let formData = new FormData()
        formData.append('image', this.file)
        formData.append('titleTH', this.product.title_th)
        formData.append('titleEN', this.product.title_en)
        formData.append('bodyTH', this.product.body_th)
        formData.append('bodyEN', this.product.body_en)
        formData.append('isPin', this.product.is_pin)
        formData.append('eventId', this.product.event_id)

        NewsService.createNews(formData)
          .then(() => {
            this.$swal.fire({
              icon: 'success',
              title: 'News created successfully',
            })
            this.loading = !this.loading
            this.dialog1 = false
            this.resetData()
            this.readData()
          })
          .catch(err => {
            const text = util.inspect(err.response, false, null, true)

            this.$swal.fire({
              icon: 'error',
              // TODO: remove + err after fix bug
              title: `${err.response.data.message} / Error: ${err} / err status code: ${err.response.status} / error.response: ${text}`,
            })
            console.error('err', util.inspect(err.response, false, null, true))

            this.loading = !this.loading
            if (err.response.status === 401) {
              this.removeToken()
              this.$router.push({ name: 'Admin' })
            }
          })
      }
    },
    resetData() {
      this.product = {
        body_en: '',
        body_th: '',
        is_new: 1,
        title_en: '',
        title_th: '',
        is_pin: false,
        event_id: 0,
      }

      this.fileSingle = null
      this.file = ''
    },
    addnew() {
      this.modal = 'new'
      this.activeItem = 'New News'
      this.resetData()
      this.dialog1 = true
    },
    editItem(product) {
      this.modal = 'edit'
      this.activeItem = 'Edit News'
      this.product = product
      this.dialog1 = true
    },
    deleteItem(doc) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
        })
        .then(result => {
          if (result.value) {
            this.loading = !this.loading
            NewsService.deleteNews(doc.id)
              .then(() => {
                this.$swal.fire({
                  icon: 'success',
                  title: 'News delete in successfully',
                })
                this.loading = !this.loading
                this.readData()
              })
              .catch(err => {
                this.$swal.fire({
                  icon: 'error',
                  title: `${err.response.data.message}`,
                })
                this.loading = !this.loading
                if (err.response.status === 401) {
                  this.removeToken()
                  this.$router.push({ name: 'Admin' })
                }
              })
          }
        })
    },
    save() {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, edit it!',
        })
        .then(result => {
          if (result.value) {
            this.loading = !this.loading
            let formData = new FormData()
            formData.append('image', this.file)
            formData.append('titleTH', this.product.title_th)
            formData.append('titleEN', this.product.title_en)
            formData.append('bodyTH', this.product.body_th)
            formData.append('bodyEN', this.product.body_en)
            formData.append('isNew', this.product.is_new)
            formData.append('isPin', this.product.is_pin)
            formData.append('eventId', this.product.event_id)

            NewsService.updateNews(this.product.id, formData)
              .then(() => {
                this.dialog1 = false
                this.$swal.fire({
                  icon: 'success',
                  title: 'News edited successfully',
                })
                this.loading = !this.loading
                this.resetData()
                this.readData()
              })
              .catch(err => {
                this.$swal.fire({
                  icon: 'error',
                  title: `${err.response.data.message}`,
                })
                this.loading = !this.loading
                if (err.response.status === 401) {
                  this.removeToken()
                  this.$router.push({ name: 'Admin' })
                }
              })
          }
        })
    },
  },
}
</script>

<style scoped></style>
