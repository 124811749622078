<template>
  <div>
    <NewsTable />
  </div>
</template>

<script>
import NewsTable from '@/components/Admin/News/NewsTable'

export default {
  name: 'News',
  components: { NewsTable },
}
</script>

<style scoped></style>
